import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const coupons = getBaseUrl2() + "api/admin/coupons";
const addcoupons = getBaseUrl2() + "api/admin/coupons";

export const deletecoupon_api = async (id, data) => {
  let config = {
    method: "delete",
    url: getBaseUrl2() + `api/admin/coupons/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const updatecoupons_api = async (id, data) => {
  let config = {
    method: "patch",
    url: getBaseUrl2() + `api/admin/coupons/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addcoupons_api = async (data) => {
  let config = {
    method: "post",
    url: addcoupons,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAlloffer = async (data) => {
  let config = {
    method: "get",
    url: coupons,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
