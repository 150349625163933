import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const user_delete = getBaseUrl() + "admin/deleteUser";
const user_status = getBaseUrl() + "admin/updateUser";
const user_update = getBaseUrl() + "admin/updateUser";
const users_list = getBaseUrl2() + "api/admin/users";
const upload_user_invoice = getBaseUrl2() + "api/admin/upload_user_invoice";

export const user_update_api = async (data) => {
  let config = {
    method: "patch",
    url: user_update,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_status_api = async (data) => {
  let config = {
    method: "patch",
    url: user_status,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_delete_api = async (data) => {
  let config = {
    method: "DELETE",
    url: user_delete,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const upload_user_invoice_api = async (data) => {
  let config = {
    method: "post",
    url: upload_user_invoice,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const fetchAlluserbookings = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl2() + `api/admin/users/bookings`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllUsers = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl2() + `api/admin/users?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
