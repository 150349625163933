import React from "react";
import ServiceRequests from "../components/ServiceRequests/ServiceRequests";

const ServiceRequestsPage = () => {
  return (
    <div>
      <ServiceRequests />
    </div>
  );
};

export default ServiceRequestsPage;
