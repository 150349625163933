import React, { useState, useEffect } from "react";
import s from "./Offers.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addcoupons_api, updatecoupons_api } from "../api/offer";
const AddOffers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data?._id;
  const [code, setcode] = useState("");
  const [offer_amount, setoffer_amount] = useState("");
  const [expiry, setexpiry] = useState("");
  const [amount_type, setamount_type] = useState("");
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const pagetype = location.state.pagetype;
  console.log(location);
  useEffect(() => {
    settitle(location?.state?.data?.title);
    setcode(location?.state?.data?.code);
    setoffer_amount(location?.state?.data?.offer_amount);
    setexpiry(location?.state?.data?.expiry?.split("T")[0]);
    setdescription(location?.state?.data?.description);
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
      const temp = {
        title: title,
        code: code,
        offer_amount: offer_amount,
        amount_type: amount_type,
        description: description,
        expiry: expiry,
        status: true,
      };
      try {
        let res = await addcoupons_api(temp);
        if (res.data.status) {
          navigate("/offer-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const temp = {
        title: title,
        code: code,
        offer_amount: offer_amount,
        amount_type: amount_type,
        description: description,
        expiry: expiry,
        status: true,
      };
      try {
        let res = await updatecoupons_api(id, temp);
        if (res.data.status) {
          console.log(res);
          navigate("/offer-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title</label>
                <input type="text" className="form-control" name="name" value={title} onChange={(e) => settitle(e.target.value)} placeholder="Title Name" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Coupon Code</label>
                <input type="text" className="form-control" name="name" value={code} onChange={(e) => setcode(e.target.value)} placeholder="code" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Type</label>

                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={amount_type}
                  onChange={(e) => setamount_type(e.target.value)}
                  name="status"
                >
                  <option value="">Select Type</option>
                  <option value="percent">Percent</option>
                  <option value="fix">Fixed</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Offer Amount</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={offer_amount}
                  onChange={(e) => setoffer_amount(e.target.value)}
                  placeholder="offer amount"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Expiry Date</label>
                <input type="date" className="form-control" name="name" value={expiry} onChange={(e) => setexpiry(e.target.value)} placeholder="offer amount" />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  rows={3}
                  name="name"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  placeholder="description"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddOffers;
