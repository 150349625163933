import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const addSkill = getBaseUrl2() + "api/admin/service";
const updateSkill = getBaseUrl2();
const deleteSkill = getBaseUrl2();
const getSkills = getBaseUrl2() + "api/admin/service";
const status_update_skill = getBaseUrl2();

export const update_skill_api = async (id, data) => {
  console.log(id, data);
  let config = {
    method: "PATCH",
    url: updateSkill + `api/admin/service/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const add_skill_api = async (data) => {
  let config = {
    method: "post",
    url: addSkill,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_update_skill_api = async (id, data) => {
  let config = {
    method: "patch",
    url: status_update_skill + `api/admin/service/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteSkill_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteSkill + `api/admin/service/${data}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllSkills = async (data) => {
  let config = {
    method: "get",
    url: getSkills + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    // url: getSkills,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
