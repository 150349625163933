import React, { useState, useEffect } from "react";
import s from "./role.module.css";
import { BiArrowBack } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { assign_categ_api, assign_cities_api, getCategories_api, get_city, get_state, getsubCategoriesbyid_api } from "../api/category";
import { vendor_details_api } from "../api/testimonial";
import Loder from "../../Loder/Loder";
const Allocatevendor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isloading, setisloading] = useState(false);
  const vendorID = location.state._id;
  const [name, setname] = useState("");
  const [statevalue, setstatevalue] = useState("");
  const [cityvalue, setcityvalue] = useState("");
  const [Assigncity, setAssigncity] = useState([]);
  const [Assigncategory, setAssigncategory] = useState([]);

  useEffect(() => {
    setname(location?.state?.data?.role_name);
  }, [location]);

  const [allcategories, setallcategories] = useState([]);
  const [subcategoriesList, setsubcategoriesList] = useState([]);

  useEffect(() => {
    getAllcategory();
    vendor_details_fun(vendorID);
  }, [vendorID]);
  const vendor_details_fun = async (vendorID) => {
    try {
      let res = await vendor_details_api(vendorID);
      if (res.data.status) {
        const cc = res?.data?.data?.vendor?.cities?.map((data) => {
          return {
            cityId: data._id,
            name: data.name,
          };
        });
        const categories = res?.data?.data?.vendor?.categories?.map((data) => {
          return {
            cityId: data._id,
            name: data.name,
          };
        });

        setAssigncity(cc);
        setAssigncategory(categories);
        console.log(res);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const getAllcategory = async () => {
    setisloading(true);
    const temp = {
      page: "",
      limit: "",
      search: "",
    };
    try {
      let res = await getCategories_api(temp);
      if (res.data.status) {
        console.log(res?.data?.data.categories);
        setallcategories(res?.data?.data.categories);
        setisloading(false);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        setisloading(false);
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      setisloading(false);
      console.log(error);
    }
  };

  const getsubcategorybyID = async (data) => {
    setisloading(true);
    try {
      let res = await getsubCategoriesbyid_api(data);
      if (res.data.status) {
        setisloading(false);
        setsubcategoriesList(res?.data?.data?.subcategories);
      } else {
        setisloading(false);

        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      setisloading(false);

      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  useEffect(() => {
    get_state_fn();
  }, []);
  const [States, setStates] = useState([]);
  const [Citys, setCitys] = useState([]);
  const get_state_fn = async () => {
    setisloading(true);
    try {
      const res = await get_state();
      if (res.data.status) {
        setStates(res.data.data.states);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async (data) => {
    setisloading(true);
    setstatevalue(data);
    try {
      const res = await get_city(data);
      if (res.data.status) {
        setisloading(false);
        setCitys(res.data.data.cities);
      }
    } catch (error) {
      setisloading(false);

      console.log("data response error:::", error);
    }
  };

  function getSelectedOption() {
    // Get the select element
    var selectElement = document.getElementById("mySelect");
    // Get the selected option
    var selectedOption = selectElement.options[selectElement.selectedIndex];

    // Get the value and text of the selected option
    var selectedValue = selectedOption.value;
    var selectedText = selectedOption.text;
    setAssigncity([
      ...Assigncity,
      {
        cityId: selectedValue,
        name: selectedText,
      },
    ]);
  }
  function getSelectedOptionCategory() {
    // Get the select element
    var selectElement = document.getElementById("mySelect2");
    // Get the selected option
    var selectedOption = selectElement.options[selectElement.selectedIndex];

    // Get the value and text of the selected option
    var selectedValue = selectedOption.value;
    var selectedText = selectedOption.text;
    setAssigncategory([
      ...Assigncategory,
      {
        cityId: selectedValue,
        name: selectedText,
      },
    ]);
  }
  const removeItemFunc = (item) => {
    const newArray = Assigncity.filter((data) => data.cityId !== item.cityId);
    setAssigncity(newArray);
  };
  const removecatItemFunc = (item) => {
    const newArray = Assigncategory.filter((data) => data.cityId !== item.cityId);
    setAssigncategory(newArray);
  };

  const assign_cities_function = async () => {
    try {
      const temp = {
        cities: Assigncity.map((data) => data.cityId).toString(),
      };
      let res = await assign_cities_api(vendorID, temp);
      if (res.data.status) {
        console.log(res);
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const assign_categ_function = async () => {
    try {
      const temp = {
        categories: Assigncategory.map((data) => data.cityId).toString(),
      };
      let res = await assign_categ_api(vendorID, temp);
      if (res.data.status) {
        console.log(res);
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
        <div className={s["title"]} onClick={() => navigate(-1)}>
          <BiArrowBack />
          Back
        </div>
        <Card className={s["admin_container"]} style={{ marginBottom: "5px" }}>
          <h4 style={{ paddingBottom: "1rem" }}>Assign Cities</h4>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select State</label>
                <select
                  class="form-control"
                  value={statevalue}
                  onChange={(e) => {
                    setcityvalue("");
                    get_city_fn(e.target.value);
                  }}
                >
                  <option>Select State</option>
                  {States.map((data, index) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select City</label>
                <select
                  class="form-control"
                  id="mySelect"
                  onChange={(e) => {
                    getSelectedOption();
                  }}
                >
                  <option>Select City</option>
                  {Citys?.map((data, index) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="seleced">
                {Assigncity &&
                  Assigncity.map((item) => (
                    <span className="seleced_item">
                      {item.name}
                      <RxCross2
                        onClick={() => removeItemFunc(item)}
                        style={{
                          color: "var(--main-color)",
                          marginLeft: "0.3rem",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  ))}
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => assign_cities_function()}>
            <Custombutton>Assign Cities</Custombutton>
          </div>
        </Card>
        <Card className={s["admin_container"]}>
          <h4 style={{ paddingBottom: "1rem" }}>Assign Category</h4>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Parent Category</label>
                <select
                  class="form-select"
                  id="mySelect2"
                  onChange={(e) => {
                    getSelectedOptionCategory();
                  }}
                >
                  <option>Parent Category</option>
                  {allcategories.map((data, index) => (
                    <option key={index} value={data._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="seleced">
                {Assigncategory &&
                  Assigncategory.map((item) => (
                    <span className="seleced_item">
                      {item.name}
                      <RxCross2
                        onClick={() => removecatItemFunc(item)}
                        style={{
                          color: "var(--main-color)",
                          marginLeft: "0.3rem",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  ))}
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => assign_categ_function()}>
            <Custombutton>Assign Category</Custombutton>
          </div>
        </Card>
      </div>
      <Loder loading={isloading} />
    </>
  );
};

export default Allocatevendor;
