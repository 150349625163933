import React from "react";
import ConsultRequests from "../components/ConsultRequests/ConsultRequests";

const ConsultRequestsPage = () => {
  return (
    <div>
      <ConsultRequests />
    </div>
  );
};

export default ConsultRequestsPage;
