import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./ServiceRequests.module.css";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import { Pagination } from "@mui/material";
import { BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import callrequest_api, { service_requests_api } from "../api/callrequest";

const ServiceRequests = () => {
  const navigate = useNavigate();
  const [isloading, setisLoading] = useState(false);
  const [allservicerequest, setallservicerequest] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchallservicerequestFunc();
  }, [pageCount, search]);

  const fetchallservicerequestFunc = async (data) => {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await service_requests_api(temp);
      if (res.data.status) {
        setallservicerequest(res?.data?.data?.service_requests);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  return (
    <div className="container">
      <div className="beat_heading">
        <div className={s["user-list-title"]}>
          <h3>Service Requests List</h3>
        </div>
        <div className={s["user-list-heading"]}>
          <div className={s["user-list-title"]}>
            <div className="beat_left"></div>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search name..."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S.no</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Phone</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Industry</StyledTableCell>
              <StyledTableCell align="center">Created_at</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allservicerequest?.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{index + 1}. </StyledTableCell>
                <StyledTableCell>{row?.name} </StyledTableCell>
                <StyledTableCell>{row?.email} </StyledTableCell>
                <StyledTableCell>{row?.phone}</StyledTableCell>
                <StyledTableCell>{row?.requirement} </StyledTableCell>
                <StyledTableCell>{row?.industry} </StyledTableCell>
                <StyledTableCell>{row?.user?.created_at} </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allservicerequest?.length <= 0 && <DataNotFound />}
        {allservicerequest?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>

      <Loder loading={isloading} />
    </div>
  );
};

export default ServiceRequests;
