import React from "react";
import Allocatevendor from "../components/Role/AllocateVendor";
const AllocatevendorPage = () => {
  return (
    <>
      <Allocatevendor />
    </>
  );
};

export default AllocatevendorPage;
