import React from "react";
import AddBanner from "../components/Banner/AddBanner";
const Banneradd = () => {
  return (
    <>
      <AddBanner />
    </>
  );
};

export default Banneradd;
