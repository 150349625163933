import React from "react";
import Vendor from "../components/Testimonial/VendorList";

const vendorPage = () => {
  return (
    <div>
      <Vendor />
    </div>
  );
};

export default vendorPage;
