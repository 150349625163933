import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import s from "./sidebar.module.css";
import { FiUsers } from "react-icons/fi";
import { MdOutlineArticle, MdEvent } from "react-icons/md";
import { GiKnightBanner } from "react-icons/gi";
import logo from "../../assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";
import { BiCategory } from "react-icons/bi";
import { MdOutlineCategory } from "react-icons/md";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { MdMiscellaneousServices } from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";
import { BiSolidOffer } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { GrBusinessService } from "react-icons/gr";
const Sidebar = ({ open }) => {
  const navigate = useNavigate();
  const [menuItems, setmenuItems] = useState([
    {
      title: " Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },
    {
      title: "Banner",
      icons: <GiKnightBanner size={20} />,
      navigate: "banner",
      show: false,
      subItems: [],
    },

    {
      title: " Users",
      icons: <FiUsers size={20} />,
      navigate: "users",
      show: false,
      subItems: [],
    },

    {
      title: "Category",
      icons: <BiCategory size={20} />,
      navigate: "category-list",
      show: false,
      subItems: [],
    },
    {
      title: "Sub Categories",
      icons: <MdOutlineCategory size={20} />,
      navigate: "sub-categories-list",
      show: false,
      subItems: [],
    },
    {
      title: "Services",
      icons: <GrBusinessService size={20} />,
      navigate: "service-list",
      show: false,
      subItems: [],
    },
    {
      title: "Vendor",
      icons: <FaUsers size={20} />,
      navigate: "vendor-list",
      show: false,
      subItems: [],
    },
    {
      title: " Booking List",
      icons: <MdOutlineArticle size={20} />,
      navigate: "booking-list",
      show: false,
      subItems: [],
    },
    {
      title: " Callback Requests",
      icons: <VscGitPullRequestGoToChanges size={20} />,
      navigate: "call-request",
      show: false,
      subItems: [],
    },
    {
      title: "Service Requests",
      icons: <MdMiscellaneousServices size={20} />,
      navigate: "service-requests",
      show: false,
      subItems: [],
    },
    {
      title: "Consults Requests",
      icons: <RiCustomerService2Line size={20} />,
      navigate: "consult-requests",
      show: false,
      subItems: [],
    },
    {
      title: "Offers",
      icons: <BiSolidOffer size={20} />,
      navigate: "offer-list",
      show: false,
      subItems: [],
    },
  ]);
  return (
    <>
      <section className={s["sidebar"]}>
        <div className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`}`} style={{ marginLeft: "1rem" }}>
          <img src={logo} alt="logo" draggable="false" />
        </div>
        {menuItems.map((data, i) => (
          <div className={s["sidebar-content"]}>
            <div className={s["sidebar-item"]} onClick={() => navigate(`/${data?.navigate}`)}>
              <div className="sidebaricons">{data.icons}</div>
              <div className={open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`}>{data.title}</div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Sidebar;
