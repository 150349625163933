import React from "react";
import UserDetails from "../components/Users/UserDetails";
const UserDetailsPage = () => {
  return (
    <>
      <UserDetails />
    </>
  );
};

export default UserDetailsPage;
