import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./testimonial.module.css";
import { BiArrowBack } from "react-icons/bi";
import Custombutton from "../../Common/Custombutton";
import { get_city, get_state } from "../api/category";
import { MdVerified } from "react-icons/md";
import { admin_getvendor_by_id_api, admin_ipdatevendor_details_api, admin_updatevendor_details_api, verifydoc_api } from "../api/testimonial";
import { notificationHandler } from "../../utils/Notification";
import RejectDoc from "../Dialogbox/RejectDoc";
import Loder from "../../Loder/Loder";
const VendorProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [vendordocuments, setvendordocuments] = useState([]);
  const [rejectDocpop, setrejectDocpop] = useState(false);
  const [rejectdata, setrejectdata] = useState("");
  const [bank_details, setbank_details] = useState("");
  const { _id } = location.state;
  const [isloading, setisloading] = useState(false);
  const [userdata, setuserdata] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    building: "",
    street: "",
    locality: "",
    city: "",
    state: "",
    country: "INDIA",
    gstNo: "",
    panNo: "",
    aadharNo: "",
    pincode: "",
  });

  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [stateuser, setStateUser] = useState("");
  const [cityUser, setCityUser] = useState("");
  useEffect(() => {
    get_state_fn();
    getvendor_by_id();
  }, []);
  useEffect(() => {
    get_city_fn();
  }, [stateuser]);

  const get_state_fn = async () => {
    try {
      const res = await get_state();
      if (res.data.status) {
        setStates(res.data.data.states);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async () => {
    try {
      const res = await get_city(stateuser);
      if (res.data.status) {
        setCitys(res.data.data.cities);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  const userinfofun = (e) => {
    setuserdata({ ...userdata, [e.target.name]: e.target.value });
  };

  const docverifyfun = async (data) => {
    try {
      const temp = {
        doc_id: data._id,
        status: "true",
        reason: "",
      };
      let res = await verifydoc_api(_id, temp);
      if (res.data.status) {
        getvendor_by_id();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const admin_updatevendor_details = async () => {
    try {
      const temp = {
        phone: userdata.phone,
        name: userdata.name,
        email: userdata.email,
        company: userdata.company,
        building: userdata.building,
        street: userdata.street,
        locality: userdata.locality,
        state: stateuser,
        city: cityUser,
        country: userdata.country,
        gst_no: userdata.gstNo,
        pan_no: userdata.panNo,
        aadhar_no: userdata.aadharNo,
      };
      let res = await admin_updatevendor_details_api(_id, temp);
      if (res.data.status) {
        getvendor_by_id();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getvendor_by_id = async () => {
    setisloading(true);
    try {
      let res = await admin_getvendor_by_id_api(_id);
      if (res.data.status) {
        const {
          documents,
          bank_details,
          aadhar_no,
          building,
          city_id,
          state_id,
          company,
          email,
          street,
          email_verified,
          gst_no,
          locality,
          name,
          pan_no,
          phone,
          pincode,
          profile_image,
        } = res.data.data.vendor;
        setvendordocuments(documents);
        setbank_details(bank_details);
        setuserdata({
          name: name,
          phone: phone,
          email: email,
          company: company,
          building: building,
          street: street,
          locality: locality,
          city: city_id,
          state: state_id,
          country: "INDIA",
          gstNo: gst_no,
          panNo: pan_no,
          aadharNo: aadhar_no,
          pincode: pincode,
        });
        setisloading(false);
      } else {
        console.log("status false!");
        setisloading(false);
      }
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
  };

  const filename = (data) => {
    if (data.name == "gst_certificate") return "GST Certificate";
    if (data.name == "company_pan") return "Company Pan";
    if (data.name == "aadhar_card") return "Aadhar Card";
    if (data.name == "msme_certificate") return "MSME Certificate";
    if (data.name == "cancelled_cheque") return "Cancelled Cheque";
    if (data.name == "other_doc") return "Other Doc";
  };
  return (
    <div>
      <div className="">
        <div className={s["title"]} onClick={() => navigate(-1)}>
          <BiArrowBack />
          Back
        </div>
        <Card className={s["admin_container"]} style={{ marginBlock: "1rem" }}>
          <div className={s["title"]}>KYC Documents</div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {vendordocuments?.map((data, i) => (
              <>
                <Grid item xs={6}>
                  <div className="form-group" style={{ textAlign: "center" }}>
                    <div>
                      <label for="exampleInputPassword1">
                        {filename(data)}
                        <span style={{ color: data.verified ? "blue" : "" }}>
                          <MdVerified size={20} />
                        </span>
                      </label>
                    </div>
                    {data?.file?.split(".")?.pop() == "pdf" ? (
                      <a href={data?.file} target="_blank">
                        <embed src={data?.file} style={{ height: "200px", width: "200px" }} />
                        <p>View PDF</p>
                      </a>
                    ) : (
                      <a href={data?.file} target="_blank">
                        <img src={data?.file} style={{ height: "200px", width: "200px" }} />
                      </a>
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "1rem", justifyContent: "center", marginBlock: "1rem" }}>
                    <button type="button" class="btn btn-primary" onClick={() => docverifyfun(data)}>
                      Verify
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={() => {
                        setrejectdata(data);
                        setrejectDocpop(true);
                      }}
                    >
                      Reject
                    </button>
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        </Card>
        <Card className={s["admin_container"]}>
          <div className={s["title"]}>Profile Details</div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={userdata.phone}
                  disabled
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter phone"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  name="name"
                  value={userdata.name}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  name="email"
                  disabled
                  value={userdata.email}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Company Name</label>
                <input
                  type="text"
                  name="company"
                  value={userdata.company}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter company"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Building Name</label>
                <input
                  type="text"
                  name="building"
                  value={userdata.building}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter building"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Street</label>
                <input
                  type="text"
                  name="street"
                  value={userdata.street}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter street"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Locality</label>
                <input
                  type="text"
                  name="locality"
                  value={userdata.locality}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter locality"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Country</label>
                <input
                  type="text"
                  name="country"
                  disabled
                  value={userdata.country}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter country"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                  name="state"
                  value={stateuser}
                  onChange={(e) => {
                    setStateUser(e.target.value);
                    setCityUser("");
                  }}
                  className="form-control"
                  id="state"
                >
                  <option selected>Choose state</option>
                  {states?.map((elem, id) => {
                    return <option value={elem._id}>{elem.name}</option>;
                  })}
                </select>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="state">City</label>
                <select
                  name="city"
                  value={cityUser}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setCityUser(e.target.value);
                  }}
                  className="form-control"
                  id="state"
                >
                  <option selected>Choose city</option>
                  {citys?.map((elem, id) => {
                    return <option value={elem._id}>{elem.name}</option>;
                  })}
                </select>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">GST No.</label>
                <input
                  type="text"
                  name="gstNo"
                  value={userdata.gstNo}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter gst number"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">PAN No.</label>
                <input
                  type="text"
                  name="panNo"
                  value={userdata.panNo}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter pan number"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Aadhar No.</label>
                <input
                  type="text"
                  name="aadharNo"
                  value={userdata.aadharNo}
                  onChange={(e) => userinfofun(e)}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter aadhar number"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => admin_updatevendor_details()}>
            <Custombutton>Update</Custombutton>
          </div>
        </Card>

        <Card className={s["admin_container"]} style={{ marginBlock: "1rem" }}>
          <div className={s["title"]}>Bank Details</div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Bank Name</label>
                <input type="text" disabled value={bank_details?.bank_name} class="form-control" id="exampleInputPassword1" placeholder="Bank Name" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Account Holder Name</label>
                <input
                  type="text"
                  disabled
                  value={bank_details?.acc_holder_name}
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Account Holder Name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Account Number</label>
                <input type="text" disabled value={bank_details?.acc_no} class="form-control" id="exampleInputPassword1" placeholder="Account Number" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Ifsc Code</label>
                <input type="text" disabled value={bank_details?.ifsc} class="form-control" id="exampleInputPassword1" placeholder="Ifsc Code" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputPassword1">Branch Name</label>
                <input type="text" disabled value={bank_details?.branch} class="form-control" id="exampleInputPassword1" placeholder="Branch Name" />
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
      <RejectDoc open={rejectDocpop} close={() => setrejectDocpop(!rejectDocpop)} _id={_id} data={rejectdata} />
      <Loder loading={isloading} />
    </div>
  );
};

export default VendorProfile;
