import React, { useState, useEffect } from "react";
import s from "./services.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { add_skill_api, update_skill_api } from "../api/skill";
import { getCategories_api, getsubCategoriesbyid_api } from "../api/category";
import RichTextEditor from "react-rte-17";
const AddSkill = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allcategories, setallcategories] = useState([]);
  const [subcategoriesList, setsubcategoriesList] = useState([]);
  const [subcategoryId, setsubcategoryId] = useState(location?.state?.data?.sub_category?._id);
  const [categoryId, setcategoryId] = useState(location?.state?.data?.category._id);
  const [rte, setrte] = useState(RichTextEditor.createEmptyValue());

  const [formdata, setformdata] = useState({
    name: "",
    description: "",
    image: "",
    service_mode: "",
    service_charge: "",
    consult_charge: "",
    estimate_time: "",
  });

  const pagetype = location?.state?.pagetype;
  const id = location?.state?.data?._id;

  const onChange = (value) => {
    console.log("Rajat\n", value);

    setrte(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  useEffect(() => {
    if (pagetype == "Edit") {
      getsubcategorybyID(location?.state?.data?.category._id);
      const initialEditorState = RichTextEditor.createValueFromString(location?.state?.data?.description, "html");
      setrte(initialEditorState);

      console.log(initialEditorState);
      // setrte(location?.state?.data?.category?.description.toString("html"));
    }
    setformdata({
      name: location?.state?.data?.name,
      description: location?.state?.data?.description,
      image: "",
      service_mode: location?.state?.data?.service_mode,
      service_charge: location?.state?.data?.service_charge,
      consult_charge: location?.state?.data?.consult_charge,
      estimate_time: location?.state?.data?.estimate_time,
    });
  }, [location]);

  const create_event = async () => {
    if (pagetype == "Add") {
      try {
        const fd = new FormData();
        fd.append("cat_id", categoryId);
        fd.append("scat_id", subcategoryId);
        fd.append("name", formdata.name);
        fd.append("description", rte.toString("html"));
        fd.append("service_mode", formdata.service_mode);
        fd.append("image", formdata.image);
        fd.append("service_charge", formdata.service_charge);
        formdata.consult_charge && fd.append("consult_charge", formdata.consult_charge);
        fd.append("estimate_time", formdata.estimate_time);
        let res = await add_skill_api(fd);
        if (res.data.status) {
          console.log(res);
          navigate("/service-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("cat_id", categoryId);
      fd.append("scat_id", subcategoryId ? subcategoryId : "");
      fd.append("name", formdata.name);
      fd.append("description", rte.toString("html"));
      fd.append("service_mode", formdata.service_mode);
      fd.append("image", formdata.image);
      fd.append("service_charge", formdata.service_charge);
      formdata.consult_charge && fd.append("consult_charge", formdata.consult_charge);
      fd.append("estimate_time", formdata.estimate_time);
      try {
        let res = await update_skill_api(id, fd);
        if (res.data.status) {
          navigate("/service-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  const formdatafunction = (e) => {
    if (e.target.name === "image") {
      setformdata({
        ...formdata,
        [e.target.name]: e.target.files[0],
      });
      return;
    }
    setformdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getAllcategory();
  }, []);
  const getAllcategory = async () => {
    const temp = {
      page: "",
      limit: "",
      search: "",
    };
    try {
      let res = await getCategories_api(temp);
      if (res.data.status) {
        console.log(res?.data?.data.categories);
        setallcategories(res?.data?.data.categories);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const getsubcategorybyID = async (data) => {
    try {
      let res = await getsubCategoriesbyid_api(data);
      if (res.data.status) {
        console.log(res);
        setsubcategoriesList(res?.data?.data?.subcategories);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Parent Category</label>
                <select
                  class="form-select"
                  value={categoryId}
                  onChange={(e) => {
                    setcategoryId(e.target.value);
                    setsubcategoryId("");
                    getsubcategorybyID(e.target.value);
                  }}
                  aria-label="Default select example"
                >
                  <option selected>Parent Category</option>
                  {allcategories.map((data, index) => (
                    <option key={index} value={data._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Sub Category</label>
                <select class="form-select" value={subcategoryId} onChange={(e) => setsubcategoryId(e.target.value)} aria-label="Default select example">
                  <option selected>Select Sub Category</option>
                  {subcategoriesList.map((data, index) => (
                    <option key={index} value={data._id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Services Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formdata.name}
                  onChange={(e) => formdatafunction(e)}
                  placeholder="Services Name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="image" placeholder="" accept="image/*" onChange={(e) => formdatafunction(e)} />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Service Charge</label>
                <input
                  type="number"
                  className="form-control"
                  name="service_charge"
                  value={formdata.service_charge}
                  onChange={(e) => formdatafunction(e)}
                  placeholder="Service Charge"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Service Mode</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={formdata.service_mode}
                      onChange={(e) => formdatafunction(e)}
                      id="exampleFormControlSelect1"
                      name="service_mode"
                    >
                      <option>Select Type </option>
                      <option value="online">Online </option>
                      <option value="onsite">Onsite</option>
                      <option value="both">Both</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Consult Charge</label>
                <input
                  type="number"
                  // disabled={formdata.service_mode === "online" ? true : false}
                  className="form-control"
                  name="consult_charge"
                  value={formdata.consult_charge}
                  onChange={(e) => formdatafunction(e)}
                  placeholder="Consult Charge"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Estimate service Time(optional)</label>
                <input
                  type="text"
                  className="form-control"
                  name="estimate_time"
                  value={formdata.estimate_time}
                  onChange={(e) => formdatafunction(e)}
                  placeholder="Estimate Time"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <RichTextEditor value={rte} className="employment_jobdescribee" onChange={onChange} />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_event()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddSkill;
