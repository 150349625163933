import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getBanners = getBaseUrl2();
const statusUpdate_banner = getBaseUrl2();
const deleteBanner = getBaseUrl2();
const addBanner = getBaseUrl2() + "api/admin/subcategory";
const updateBanner = getBaseUrl2();

export const updateBanner_api = async (id, data) => {
  let config = {
    method: "patch",
    url: updateBanner + `api/admin/subcategory/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addBanner_api = async (data) => {
  let config = {
    method: "post",
    url: addBanner,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteBanner_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteBanner + `api/admin/subcategory/${data}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const statusUpdate_banner_api = async (id, data) => {
  let config = {
    method: "patch",
    url: statusUpdate_banner + `api/admin/subcategory/${id}`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getBanners_api = async (data) => {
  let config = {
    method: "get",
    // url: getBanners + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: getBanners + `api/admin/subcategory`,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}`, "Content-Type": "application/json" },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
