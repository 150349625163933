import { Card } from "@mui/material";
import React from "react";
import s from "./dashboard.module.css";
import Graph from "./Graph";
import Graph1 from "./Graph1";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const cardArray = [
    {
      title: "Total Customer",
      icon: "20",
      navigate: "users",
    },
    {
      title: "Total vendor",
      icon: "5",
      navigate: "vendor-list",
    },

    {
      title: "Total Booking",
      icon: "17",
      navigate: "booking-list",
    },
    {
      title: "Total Amount",
      icon: "1294",
      navigate: "dashboard",
    },
  ];
  return (
    <>
      <div style={{ paddingInline: "1rem" }}>
        <div className="dashboard-section">
          <div className={s["dashboard-header-container"]}>
            {cardArray.map((data) => (
              <Card className={s["dashbord-content"]}>
                <div class={s["dashboard-main-card"]} style={{ cursor: "pointer" }} onClick={() => navigate(`/${data?.navigate}`)}>
                  <div class={s["dashboard-card-content"]}>
                    <div class={s["card-title"]}>
                      <h6>{data.title}</h6>
                      <h4>{data.icon}</h4>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className={s["graph-container"]}>
          <div className={s["graph-content"]}>
            <div className="graph-left" style={{ width: "70%" }}>
              <Graph1 />
            </div>
            <div className="graph-right" style={{ width: "30%" }}>
              <Graph />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
