import React from "react";
import OffersList from "../components/Offers/OffersList";

const OffersListPage = () => {
  return (
    <div>
      <OffersList />
    </div>
  );
};

export default OffersListPage;
