import React, { useState, useEffect } from "react";
import s from "./user.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { AiOutlineUpload } from "react-icons/ai";
import { fetchAlluserbookings, upload_user_invoice_api } from "../api/user";
import { BsEye } from "react-icons/bs";
import { notificationHandler } from "../../utils/Notification";
const UserDetails = () => {
  const navigate = useNavigate();
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [allbooking, setallbooking] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [imagedata, setimagedata] = useState(null);
  const location = useLocation();
  console.log(location);
  const { first_name, last_name } = location?.state;
  const id = location.state._id;
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllbookingFunc();
  }, [pageCount, search]);

  async function fetchAllbookingFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        user_id: id,
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await fetchAlluserbookings(temp);
      if (res.data.status) {
        setallbooking(res.data.data.bookings);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const invoice_update_img = async (e, data) => {
    setimagedata(e.target.files);
    const fd = new FormData();
    fd.append("invoice_image", e.target.files[0]);
    fd.append("booking", data._id);
    try {
      let res = await upload_user_invoice_api(fd);
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        fetchAllbookingFunc();
        setisLoading(false);
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
        console.log("status false!");
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  return (
    <>
      <div className={s["admin_container"]}>
        <div className={s["main-heading-content"]}>
          <div className="main-heading-right">
            <h3>{first_name + " " + last_name}</h3>
          </div>
          <div className="main-heading-left">
            <div className={s["title"]} onClick={() => navigate(-1)}>
              <BiArrowBack />
              Back
            </div>
          </div>
        </div>
        <Card className={s["card-header"]}>
          <div className={s["title"]}>Booking Details ({allbooking.length})</div>
          {allbooking?.map((data, index) => (
            <div class="card mb-3">
              <div className={s["card-header-header"]}>
                <div>
                  <h5 style={{ color: "#fff" }}>#{data?._id}</h5>
                  <p class="card-text" style={{ textTransform: "capitalize" }}>
                    Booking Status : <b>{data?.booking_status}</b>
                  </p>
                </div>
                {data.user_invoice ? (
                  <div style={{ cursor: "pointer" }}>
                    <a href={data.user_invoice} target="_blank">
                      <h6 style={{ color: "#fff" }}>
                        Invoice <BsEye size={20} />
                      </h6>
                    </a>
                  </div>
                ) : (
                  <div style={{ cursor: "pointer" }}>
                    <label htmlFor="contained-button-file" class="dropdown-item">
                      Invoice <AiOutlineUpload size={20} />
                    </label>
                    <input
                      type="file"
                      accept=".pdf, .jpeg, .jpg, .png"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      onChange={(e) => invoice_update_img(e, data)}
                    />
                  </div>
                )}
              </div>
              <div class="card-body">
                <h5 class="card-title">Service Details</h5>
                <p class="card-text">Name : {data?.service?.name}</p>
                <p class="card-text">Service Charge : ₹ {data?.service?.service_charge}</p>
                <p class="card-text">Service Mode : {data?.service?.service_mode}</p>
                <p class="card-text">Consult Charge : ₹ {data?.service?.consult_charge}</p>
                <p class="card-text">Service Charge Paid :{data?.service_charge_paid ? "Paid" : "unpaid"}</p>
              </div>
              <div class="card-body">
                <h5 class="card-title">Vendor Details</h5>
                <p class="card-text">Name : {data?.vendor?.name}</p>
                <p class="card-text">Email : {data?.vendor?.email}</p>
                <p class="card-text">Phone : {data?.vendor?.phone}</p>
                <p class="card-text">Address : {data?.vendor?.address}</p>
              </div>
              <div class="card-body">
                <h5 class="card-title">Service Address</h5>
                <p class="card-text">Name : {data?.address?.name}</p>
                <p class="card-text">Phone : {data?.address?.phone}</p>
                <p class="card-text">
                  Address : {data?.address?.building},{data?.address?.city},{data?.address?.state}-{data?.address?.pincode}
                </p>
                <p class="card-text">Landmark : {data?.address?.landmark}</p>
                <p class="card-text">Pincode : {data?.address?.pincode}</p>
              </div>
            </div>
          ))}
        </Card>
      </div>
    </>
  );
};

export default UserDetails;
