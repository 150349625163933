import React from "react";
import AddOffers from "../components/Offers/AddOffers";

const AddOffersPage = () => {
  return (
    <div>
      <AddOffers />
    </div>
  );
};

export default AddOffersPage;
