import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./ConsultRequests.module.css";
import { Pagination } from "@mui/material";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { consult_resolved_api, fetchconsults_api } from "../api/consult";
import ConsultResolved from "../Dialogbox/ConsultResolved";
import { notificationHandler } from "../../utils/Notification";

const ConsultRequests = () => {
  const [isLoading, setisLoading] = useState(false);
  const [consults, setconsults] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [consultId, setconsultId] = useState("");
  const [ConsultResolvedpopup, setConsultResolvedpopup] = useState(false);
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchconsultsFunc();
  }, [pageCount, search]);

  async function fetchconsultsFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await fetchconsults_api(temp);
      if (res.data.status) {
        setconsults(res?.data?.data.consults);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const consultupdate = async () => {
    try {
      let res = await consult_resolved_api(consultId);
      if (res.data.status) {
        setisLoading(false);
        fetchconsultsFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  return (
    <div className="container">
      <div className={s["role-list-title"]}>
        <h3>Consult Requests List</h3>
      </div>

      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Service Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Phone</StyledTableCell>
              <StyledTableCell align="center">Consult Charge</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consults.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row?.user?.first_name + " " + row?.user?.last_name} </StyledTableCell>
                <StyledTableCell>{row.service.name} </StyledTableCell>
                <StyledTableCell>{row?.user?.email} </StyledTableCell>
                <StyledTableCell>{row?.user?.phone} </StyledTableCell>
                <StyledTableCell>{row?.consult_charge} </StyledTableCell>
                <StyledTableCell>
                  <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>{row?.consult_status} </span>
                </StyledTableCell>
                <StyledTableCell>
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      setConsultResolvedpopup(true);
                      setconsultId(row._id);
                    }}
                  >
                    Resolved
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {consults.length <= 0 && <DataNotFound />}
        {consults?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>
      <ConsultResolved open={ConsultResolvedpopup} close={() => setConsultResolvedpopup(!ConsultResolvedpopup)} onsubmit={() => consultupdate()} />
      <Loder loading={isLoading} />
    </div>
  );
};

export default ConsultRequests;
