import React from "react";
import SubAdminList from "../components/Banner/BannerList";

const Banner = () => {
  return (
    <>
      <SubAdminList />
    </>
  );
};

export default Banner;
