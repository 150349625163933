import React from "react";
import CallbackRequests from "../components/CallbackRequests/CallbackRequests";

const CallbackRequestsPage = () => {
  return (
    <div>
      <CallbackRequests />
    </div>
  );
};

export default CallbackRequestsPage;
