import React from "react";
import BookingListPage from "../components/Role/BookingListPage";

const BookingList = () => {
  return (
    <>
      <BookingListPage />
    </>
  );
};

export default BookingList;
